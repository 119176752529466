#suite {
    .details {
        h2 {
            margin: 15px 0 40px 0;
        }
        p {
            margin-bottom: 25px;
            
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .galleryCarousel {
            max-width: 80%;
            margin: 50px auto 70px auto;
            
            @include touch {
                max-width: 100%;
            }
            
            .carousel-cell {
                height: 100%;
                img {
                    display: block;
                    max-height: 100%;
                }
            }
            .carousel-cell {
                width: 100%; /* full width */
                height: 500px;
                background: $dkBlue;
                /* center images in cells with flexbox */
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include tablet {
                    height: 450px;
                }
                @include mobile {
                    height: 350px;
                }
                @include small-mobile {
                    height: 300px;
                }
              }

            &.is-fullscreen .carousel-cell {
                height: 100%;
            }
            .flickity-button {
                background: rgba(0, 0, 0, 0.5);
                color: $white;
                @extend .smooth;
                
                &:hover {
                    background: rgba(0, 0, 0, 0.75);;
                }
            }
            .flickity-page-dots {
                .dot {
                    width: 12px;
                    height: 12px;
                    margin: 0 6px;
                    border-radius: 2px;
                    background: $blue;
                    opacity: 1;
                    &.is-selected {
                        background: $gold;
                    }
                }
            }
        }
        .specs {
            margin: 0 0 64px 0;
        }
        .packages {
            margin: 0 0 64px 0;
            
            
            h3 {
                margin-bottom: 30px;
            }
            ul {
                li {
                    border-bottom: 1px solid $sand;
                    padding: 12px 0;

                    p,.button {
                        display: inline-block;
                        margin: 0;
                    }
                    .name {
                        width: 50%;
                    }
                    .price {
                        width: 25%;
                        
                        span {
                            font-weight: 700;
                        }
                    }
                    .button {
                        width: 20%;
                        padding: 0;
                        border: 0;
                        background: none;
                        color: $red;
                        height: auto;
                        position: relative;
                        text-align: right;
                        float: right;
                        
                        &:hover {
                            color: $gold;
                        }
                    }
                    .description {
                        width: 100%;
                        display: block;
                        font-size: 17px;
                        margin-top: 5px;
                    }
                    @include touch {
                        .name,.price,.button {
                            display: block;
                            width: 100%;
                            float: none;
                            text-align: left;
                            top: 0;
                        }
                    }
                }
            }
        }
    }
    .exploreSuites {
        background: $paper;
        padding: 64px 0;
        
        h2, h3 {
            text-align: center;
        }
        .divi {
            margin-bottom: 64px;
        }
        .column {
            flex: auto;
        }
    }
}