footer {
    .badges {
        position: relative;
        z-index: 100;
        bottom: 0;
        right: 0;
        text-align: center;
        margin-bottom: 50px;
        
        img {
            height: 70px;
            margin: 5px 10px;
        }
    }
    .footerContent {
        padding: 50px 0 70px 0;
        background: $blue;
        color: $white;
        
        p, li {
            color: $white;
        }
        
        .location {
            float: left;
            margin-right: 70px;
            
            @include desktop {
                margin-right: 60px;
            }
            @include small-desktop {
                margin-right: 55px;
            }
            @include tablet {
                float: none;
                margin-right: 0;
                border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                padding-bottom: 30px;
                margin-bottom: 30px;
            }
            @include handheld {
                float: none;
                margin-right: 0;
                border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                padding-bottom: 30px;
                margin-bottom: 30px;
            }
            
            h6 {
                font-family: $primary;
                font-size: 40px;
                margin-bottom: 25px;
                
                @include tablet {
                    font-size: 34px!important;
                }
                @include handheld {
                    font-size: 34px!important;
                }
            }
            li {
                margin-bottom: 15px;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
                
                @include tablet {
                    font-size: .9em!important;
                }
                @include handheld {
                    font-size: .9em!important;
                }
            }
            a {
                color: $white;

                &:hover {
                    color: $gold;
                }
            }
        }
        .subscribe {
            float: right;
            width: 400px;
            
            @include small-desktop {
                width: 100%;
                border-top: 2px solid rgba(255, 255, 255, 0.3);
                padding-top: 30px;
                margin-top: 30px;
            }
            @include tablet {
                float: none;
                width: 100%;
            }
            @include handheld {
                float: none;
                width: 100%;
            }
            
            h6 {
                font-size: 25px;
                font-weight: 500;
            }
            p {
                font-size: 16px;
                margin: 15px 0;
            }
        }
        .socialLinks {
            margin-top: 30px;
            li {
                display: inline-block;
                margin-left: 6px;
                
                &:first-of-type {
                    margin-left: 0;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
            .orb {
                background: $dkBlue;
                
                &:hover {
                    background: $gold;
                }
            }
        }
    }
    
    .legal {
        background: $paper;
        padding: 20px 0;
        text-align: center;
        
        li {
            display: inline-block;
            font-size: 13px;
            text-transform: uppercase;
            color: $blue;
            margin: 0 10px;
            
            a {
                color: $blue;
                
                &:hover {
                    color: $gold;
                }
            }
        }
    }
    
}