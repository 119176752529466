#cta {
    @extend .backgroundSettings;
    min-height: 360px;
    text-align: center;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    
    @include screen {
        background-attachment: fixed;
    }
    
    .ctaContent {
        position: relative;
        padding: 180px 0;
        z-index: 200;
        
        h4 {
            font-family: $primary;
            color: $white;
            font-size: 46px;
            margin-bottom: 25px;
        }
        .button {
            background: $gold;
            color: $white;
            border-color: $gold;
            margin: 0 10px!important;
            text-transform: uppercase;
            letter-spacing: $spaced;
            
            &:hover {
                background: transparent;
            }
            
            @include handheld {
                display: block;
                margin: 0 0 25px 0!important;
            }
        }
    }
}