#menu {
    .overview {
        margin-bottom: 64px;
        
        p {
            margin-bottom: 25px;
            
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .button {
            margin-top: 25px;
        }
    }
    .menuBuilder {
        margin-bottom: 64px;
        
        .menuSection {
            margin-bottom: 15px;
        }
        //Accordion Functional Styles - this stuff should get globalized
        .header {
            @extend .smooth;
            
            .accordion-button {
                cursor: pointer;

                .fa-plus {
                    backface-visibility: hidden;
                    transform: rotate(0deg);
                    transform-style: preserve-3d;
                }

                &.expanded {
                    .fa-plus {
                        transform: rotate(45deg);
                    }
                }
            }
        }
        .accordion-content {
            overflow: hidden;
            transition: height $transition;
         }
        //End
        
        
        h2 {
            font-size: 34px;
            i {
                font-size: 22px;
                color: $red;
                position: relative;
                top: -2px;
                @extend .smooth;
            }
        }
        h3 {
            font-family: $body;
            color: $red;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: .04em;
            margin-top: 8px;
        }
        h4 {
            font-size: 26px;
            font-weight: 500;
            color: $red;
            margin: 20px 0;
        } //group title
        
        .menuGroup {
            margin: 25px 0;
            
            span {
                color: $gold;
                text-transform: uppercase;
            }
        }
    }
    .specialNotes {
        margin-bottom: 64px;
        border: 1px solid $gold;
        padding: 25px;
        
        p {
            font-size: 16px;
            
            span {
                color: $gold;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
        .legal {
            font-style: italic;
            margin-top: 12px;
        }
    }
    .exploreMenus {
        background: $paper;
        padding: 64px 0;
        text-align: center;
    }
}

.menus {
    background: $paper;
    padding: 64px 0;
    
    h2, h3 {
        text-align: center;
    }
    .divi {
        margin-bottom: 64px;
    }
    .column {
        flex: auto;
    }
}