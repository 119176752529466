#contact {
    .splitView {
        background: $paper;
        
        .imageWrapper {
            position: relative;
            
            .directions {
                position: absolute;
                bottom: 30px;
                left: 0;
                right: 0;
                width: 35%;
                margin: 0 auto;
                
                @include small-desktop {
                    width: 40%;
                }
                @include tablet {
                    width: 60%;
                }
                @include mobile {
                    width: 70%;
                }
                @include small-mobile {
                    width: 85%;
                }
            }
        }
    }
    .contacts {
        max-width: 1000px;
        margin: 64px auto 0 auto;
        
        h2 {
            text-transform: uppercase;
            font-size: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid $gold;
            margin-bottom: 20px;
        }
        .team {
            li {
                @include screen {
                    width: 50%;
                    float: left;
                    margin-bottom: 10px;
                }
                .header {
                    @extend .smooth;

                    i {
                        color: $gold;
                        font-size: 15px;
                        float: left;
                        position: relative;
                        top: 4px;
                        margin-right: 5px;
                        @extend .smooth;
                        
                        @include small-mobile {
                            font-size: 13px;
                        }
                    }
                    h4 {
                        color: $black;
                        font-size: 20px;
                        font-weight: 400;
                        text-transform: uppercase;
                        float: left;
                        
                        @include small-mobile {
                            font-size: 17px;
                            width: 85%;
                        }
                    }
                    &:hover {
                        i {
                            color: $red;
                        }
                    }

                    .accordion-button {
                        padding: 8px 0;
                        cursor: pointer;

                        .fa-plus {
                            backface-visibility: hidden;
                            transform: rotate(0deg);
                            transform-style: preserve-3d;
                            //transition: transform $transition;
                        }

                        &.expanded {
                            .fa-plus {
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
                .accordion-content {
                    overflow: hidden;
                    transition: height .6s;
                    padding-left: 15px;
                    
                    a {
                        color: $blue;
                        display: block;
                        
                        &:hover {
                            color: $gold;
                        }
                    }
                    p {
                        font-size: 18px;
                        font-weight: 500;
                        color: $blue;
                        @extend .smooth;
                        
                        &:hover {
                            color: $gold;
                        }
                    }
                    .secondaryContact {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .featureCards {
        margin: 64px 0;
    }
}