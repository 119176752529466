.video {
    width: 60vw;
    margin: 0 auto;
    
    .intrinsic-container {
        
    }
    
    @include small-desktop {
        width: 70vw;
    }
    @include tablet {
        width: 80%;
    }
    @include handheld {
        width: 90%;
    }
}