#event {
    background: $paper;
    padding-top: 64px;

    .eventDetails {
        padding-top: 40px;

        @include tablet {
            padding-top: 80px;
        }
        @include handheld {
            padding-top: 60px;
        }

        .titleRow {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 2px solid $gold;

            .venue {
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 5px;

                i {
                    color: $gold;
                    margin-right: 3px;
                }
            }
            .tagline {
                font-weight: 500;
                color: $blue;
            }
            .subheader {
                font-weight: 500;
                color: $red;
                margin-top: 10px;
            }
        }
        .overview {
            margin-bottom: 30px;

            .times {
                span {
                    color: $gold;
                    font-weight: 500;
                    letter-spacing: -.05em;
                    margin: 0 6px;
                }
            }
        }
        .featVideo {
            display: block;
            
            .videoEmbed {
                margin-bottom: 30px;
            }
        }
        .content {
            font-family: $body;
            font-size: 18px;
            line-height: 1.4em;
            p, div, ul, ol {
                margin-bottom: 25px;
            }
        }
    }
    .eventSummary {
        .lowAlert {
            background: $red;
            padding: 8px;
            text-align: center;

            p {
                color: $white;
                font-weight: 500;
            }
        }
        .pastEvent {
            margin: 30px 0;
            background: $sand;
            padding: 20px;
        }
        .eventActions {
            margin: 30px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            justify-content: center;
            max-width: 1200px;

            @media (min-width: 525px) {
                flex-direction: row;
                justify-content: flex-start;
            }

            .eventCta {
                width: 100%;

                @media (min-width: 525px) {
                    flex-basis: 200px;
                }

                .button {
                    width: 100%;
                }
            }
            .utilities {
                display: flex;
                position: relative;
                top: 2px;
                gap: 10px;

                i {
                    color: $blue;
                    position: relative;
                    top: 1px;
                }
                li {
                    display: inline-block;

                    .orb {
                        width: 42px;
                        height: 42px;
                        border: 2px solid $gold;
                        border-radius: $round;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @extend .smooth;

                        @include small-mobile {
                            width: 38px;
                            height: 38px;
                        }

                        &:hover {
                            background: $gold;

                            i {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .notes {
            border: 1px solid $sand;
            padding: 15px;

            p {
                font-size: 18px;
            }
        }
    }
}
.utility {
    &.eventFooter {
        margin: 0!important;
        margin-bottom: 0!important;
        padding: 32px 15px!important;
    }
}
#cpyBtn {
    cursor: pointer;
}