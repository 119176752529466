#home {
    .intro {
        .summary {
            padding-top: 64px;
            
            h1 {
                @include screen {
                    width: 75%;
                }
                @include small-desktop {
                    width: 85%;
                }
            }
            .subtitle {
                margin: 50px 0;
                display: inline-block;
                .divi {
                    height: 2px;
                    background: $red;

                    &.short {
                        width: 50%;
                    }
                    &.long {
                        width: 100%;
                    }
                }
                h2 {
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 24px;
                    padding: 20px 0;
                }
            }
            p {
                margin-bottom: 40px;
            }
            .button {
                margin-top: 10px;
                margin-bottom: 10px;
                
                @include handheld {
                    display: block;
                    margin-right: 0!important;
                }
            }
            .button.primary:nth-of-type(2) {
                margin-right: 10px;
                border: 2px solid $red;
                
                &:hover {
                    background: $red;
                }
            }
            .button.primary:nth-of-type(3) {
                margin-right: 0;
            }
        }
        /*img {
            border: 8px solid $white;
            position: relative;
            top: -100px;
            margin-bottom: -100px;
            
            @include touch {
                margin-top: 130px;
                border: none;
            }
        }*/
        .imageWrapper {
            width: 100%;
            height: 250px;
            @extend .backgroundSettings;
            margin: 64px 0 0 0;
        }
        .quickLinks {
            text-align: center;
            
            p {
                font-family: $primary;
                color: $red;
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 500;
                margin: 20px 0;
                letter-spacing: $spaced;
                
                @include touch {
                    margin: 20px 0;
                }
            }
            
            ul {
                border-top: 2px solid $gold;
                li {
                    font-family: $primary;
                    color: $blue;
                    font-size: 22px;
                    font-weight: 500;
                    border-bottom: 2px solid $gold;
                    
                    a {
                        width: 100%;
                        display: block;
                        padding: 20px 0;
                        color: $blue;
                        
                        &:hover {
                            background: $paper;
                            color: $red;
                        }
                    }
                }
            }
        }
    }
    .sectionTitle {
        font-size: 46px;
        text-align: center;
        padding: 64px 0;
        
        @include touch {
            font-size: 36px;
            padding: 40px 0;
        }
    }
    .featuredEvents {
        background: $paper;
        margin-top: 64px;
        
        @include touch {
            padding-bottom: 32px;
        }
        
        .listing {
            margin-bottom: 64px;
            position: relative;
            z-index: 200;
        }
        
        .imageContainer {
            position: relative;
            top: 300px;
            margin: -300px auto 300px auto;
            
            @include touch {
                top: 0;
                margin: 0 auto;
            }
        }
        .playVideo {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 68px;
            height: 68px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 45%;
            background: $blue;
            cursor: pointer;
            z-index: 500;
            border-radius: $round;
            @extend .smooth;
            animation: pulse 2s infinite;
            
            @keyframes pulse {
                0% {
                    transform: scale(0.95);
                    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
                }

                70% {
                    transform: scale(1);
                    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
                }

                100% {
                    transform: scale(0.95);
                    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
                }
            }
            
            i {
                color: $white;
                font-size: 1.2em;
            }
            &:hover {
                background: $dkBlue;
            }
        }
        .previewImage {
            max-height: 600px;
            display: block;
            position: relative;
            margin: 0 auto;
            /*top: 300px;
            margin: -300px auto 300px auto;
            
            @include touch {
                top: 0;
                margin: 0 auto;
            }*/
        }
    }
}