#mobileNav {
    position: fixed;
    right: -45vw;
    background: $paper;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    width: 45vw;
    transition: all 0.1s ease-in-out;

    &.is-active {
        right: 0;
    }
    @include screen {
        display: none;
    }
    @include handheld {
        width: 65vw;
        right: -65vw;
    }
    .mobileClose {
        position: absolute;
        font-size: 1.8em;
        color: $blue;
        top: 20px;
        right: 20px;
    }
    .navWrapper {
        padding: 60px 40px;
        
        @include xs-mobile {
            padding: 60px 30px;
        }
        .navContent {
            li {
                padding: 15px 0;
                a {
                    h5 {
                        color: $blue;
                        display: inline-block;
                    }
                    i {
                        color: $gold;
                        display: inline-block;
                    }
                }
            }
            &.subActive {}
        }
        .nav {
            &.subnav {
                display: none;
                position: absolute;
                top: 60px;
                z-index: -1;
                &.subActive {
                    z-index: 10;
                }
                li {
                    a {
                        padding: 15px 0;
                        color: $blue;
                        display: inline-block;
                        text-transform: uppercase;
                        h6 {
                            display: inline-block;
                        }
                        i {
                            color: $gold;
                            display: inline-block;
                        }

                    }
                    &.back {
                        a { 
                            color: $gold;
                        }
                    }
                }
            }
        }
    }
    .navFooter {
        display: inline-block;
        position: absolute;
        bottom: 25px;
        text-align: center;
        width: 100%;
        li {
            display: inline-block;
        }
        .search {
            color: $blue;
            font-size: .9em;
            text-transform: uppercase;
            text-align: center;;
            display: block;
            padding: 20px 0;
        }
    }
}
.mobileTrigger {
    background: $white;
    clear: both;
    display: none!important;
    text-align: right;
    padding: 20px 20px 20px 0;
    h4 {
        cursor: pointer;
        font-size: .9em;
        text-transform: uppercase;
    }
    @include touch {
        display: block!important;
    }
}

@keyframes animateInFromLeft {
    0% {
        width: 0;
        visibility: hidden;
        transform: translate3d(-100vw, 0, 0);
    }
    1% {
        width: 100%;
    }
    100% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animateInFromRight {
    0% {
        visibility: hidden;
        width: 0;
        transform: translate3d(100vw, 0, 0);
    }
    1% {
        width: 100%;
    }
    100% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animateOutToRight {
    0% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
    99% {
        width: 100%;
    }
    100% {
        visibility: hidden;
        width: 0;
        transform: translate3d(100vw, 0, 0);
    }
}

@keyframes animateOutToLeft {
    0% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
    99% {
        width: 100%;
    }
    100% {
        visibility: hidden;
        width: 0;
        transform: translate3d(-100vw, 0, 0);
    }
}

.animate-inFromLeft {
    animation: animateInFromLeft 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}

.animate-inFromRight {
    animation: animateInFromRight 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}

.animate-outToLeft {
    animation: animateOutToLeft 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}

.animate-outToRight {
    animation: animateOutToRight 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}