.contentCards {
    margin-bottom: 64px;
}

.card {
    box-shadow: none;
    margin-bottom: 30px;
    position: relative;
    
    &.contentCard {
        .cardContent {
            h5 {
                margin-bottom: 15px;
            }
            h4 {
                font-family: $primary;
                font-size: 30px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 10px;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.featureCard {
        @extend .backgroundSettings;
        text-align: center;
        padding: 100px 20px;
        h4 {
            font-family: $primary;
            color: $white;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            z-index: 200;
            margin-bottom: 10px;
        }
        h5 {
            color: $gold;
            opacity: 0;
            transform: translateY(10px);
            position: relative;
            z-index: 200;
            @extend .smooth;
            transition-delay: $delay;
        }
        
        &:hover {
            h5 {
                opacity: 1;
                transform: translateY(0);
            }
            .overlay {
                background: rgba(17, 17, 17, 0.7);
            }
        }
    }
    &.menuCard {
        @extend .backgroundSettings;
        height: 400px;
        border-radius: $radius;
        position: relative;
        margin-bottom: 0;
        
        .fa-file-pdf {
            color: $white;
            font-size: 24px;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 50;
            box-shadow: $shadow;
        }
        .cardContent {
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 500;
            padding: 20px;
            
            h3 {
                color: $white;
                float: left;
                text-align: left;
                max-width: 90%;
            }
            i {
                font-size: 22px;
                color: $gold;
                position: relative;
                top: 10px;
                float: right;
                opacity: 0;
                transform: translateX(-20px);
                @extend .smooth;
                transition-delay: $delay;
            }
        }
        .gradient {
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.45) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
            transition: background $transition ease-in-out;
        }
        .overlay {
            opacity: 0;
            background: rgba(17, 17, 17, 0.2);
        }
        &:hover {
            .overlay {
                opacity: 1;
            }
            i {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}