#additions {
    .heroImage {
        margin-bottom: 64px;
    }
    .intro {
        h1 {
            margin: 15px 0 40px 0;
        }
        p {
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    .packages {
        margin-bottom: 64px;
        
        h4 {
            font-family: $primary;
            font-weight: 700;
            font-size: 26px;
            margin-top: 40px;
            margin-bottom: 10px;
            
            span {
                color: $red;
            }
        }
        p {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}