/* BASE */
@import 'base/_variables.scss';
@import 'base/_mixins.scss';
@import 'base/_base.scss';
@import 'base/_typography.scss';
@import 'base/_buttons.scss';

/* VENDOR */
@import '~aos/dist/aos.css';

/* BLOCKS */
@import 'blocks/_header.scss';
@import 'blocks/_nav.scss';
@import 'blocks/_mobileNav.scss';
@import 'blocks/_alert.scss';
@import 'blocks/_heroSlider.scss';
@import 'blocks/_heroImage.scss';
@import 'blocks/_cta.scss';
@import 'blocks/_cards.scss';
@import 'blocks/_footer.scss';
@import 'blocks/_social.scss';
@import 'blocks/_utility.scss';

/* ELEMENTS */
@import 'elements/_video.scss';
@import 'elements/_accordian.scss';
@import 'elements/_pageIntro.scss';
@import 'elements/_splitView.scss';
@import 'elements/_simpleIntro.scss';
@import 'elements/_sidebar.scss';
@import '../../global/scss/flickity.min.css';

/* PAGES */
@import 'pages/_home.scss';
@import 'pages/_history.scss';
@import 'pages/_parking.scss';
@import 'pages/_suite.scss';
@import 'pages/_additions.scss';
@import 'pages/_activities.scss';
@import 'pages/_parties.scss';
@import 'pages/_menu.scss';
@import 'pages/_event.scss';
@import 'pages/_accomodations.scss';
@import 'pages/_contact.scss';
@import 'pages/_search.scss';
@import 'pages/_news.scss';
@import 'pages/_takeout.scss';
@import 'pages/_chefNotes.scss';