.pageIntro {
    margin: 64px 0;
    
    h1 {
        margin: 15px 0 40px 0;
    }
    h2 {
        margin: 15px 0 40px 0;
    }
    p {
        margin-bottom: 30px;
    }
    img {
        @include touch {
            margin-top: 30px;
        }
    }
}