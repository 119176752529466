#chefNotes {
	.content {
		padding: 60px 0;

		h1 {
			color: $blue!important;

			@include mobile {
		        font-size: 44px!important;
		    }
		    @include small-mobile {
		        font-size: 42px!important;
		    }
		}
        h2 {
            color: $red!important;
            font-size: 36px;
        }
        h3 {
            color: $blue!important;
            font-size: 32px;
            margin-top: 1.3em!important;
        }
        h4 {
            color: $blue!important;
            font-size: 28px;
            margin-top: 1.3em!important;
        }
        h5 {
            color: $blue!important;
            font-size: 22px;
            margin-top: 1.8em!important;
        }
        h6 {
            color: $red!important;
            font-size: 22px;
            margin-top: 1.8em!important;
        }
        ul, ol {
            @extend p;
        }
    	figure {
            margin: 64px auto;
            max-width: 960px;
                
            figcaption {
                margin-top: 8px;
                color: $sand;
                text-align: left;
            }
        }
    }
}