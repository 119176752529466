.button {
    padding: 8px 20px;
    border-radius: $radius;
    font-family: $body;
    font-size: 20px;
    font-weight: 500;
    @extend .smooth;
    
    @include small-mobile {
        display: block;
    }
    
    &.primary {
        color: $blue;
        border: 2px solid $red;
        background: transparent;
        margin-right: 10px;
        
        @include small-mobile {
            margin: 0 0 15px 0;
        }
        
        &:nth-of-type(2) {
            border-color: $gold;
            margin-right: 0;

            &:hover {
                background: $gold;
                color: $white;
            }
        }
        &:hover {
            background: $red;
            color: $white;
        }
    }
    &.directions {
        background: $blue;
        color: $white;
        padding: 8px;
        border: none;
        
        &:hover {
            background: $dkBlue;
        }
    }
    &.plain {
        color: $gold;
        background: none;
        padding: 0;
        border: none;
        
        &:hover {
            color: $blue;
        }
    }
    &.shop {
        display: none;
    }
    &.newsletter {
        background: $dkBlue;
        color: $white;
        border: none;
        
        &:hover {
            background: $gold;
        }
    }
    
    &.event {
        padding: 8px 30px;
        color: $white;
        text-transform: uppercase;
        letter-spacing: $spaced;
    }
    &.onSale {
        background: $blue;
        border: 2px solid $blue;
        
        &:hover {
            color: $blue;
            background: transparent;
        }
    }
    &.offSale {
        background: $sand;
        border: 2px solid $sand;
        cursor: not-allowed;
    }
}

#gifts {
    .button {
        &.shop {
            display: inline-block;
            text-align: left;
        }
    }
}