header {
    position: relative;
    height: 97px;
    background-color: $white;
    z-index: 999;
        
    @include tablet {
        height: 104px;
    }
    @include handheld {
        height: 62px;
    }
    .brand {
        background-color: $white;
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        box-shadow: $shadow;
        z-index: 999;
        
        img {
            width: 250px;
            
            @include small-mobile {
                width: 220px;
            }
            @include xs-mobile {
                width: 200px;
            }
        }
    }
    .mobileTrigger {
        h4 {
            font-size: 18px;
            font-weight: 600;
            
            i {
                color: $red;
            }
        }
    }
    .navElements {
        float: right;
        
        @include handheld {
            display: none;
        }
        
        .utilityNav {
            text-align: right;
            //padding: 10px 16px;
            height: 48px;
            padding-right: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            li {
                display: inline-block;
                
                &.visit {
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 700;
                    
                    a {
                        color: $gold;
            
                        &:hover {
                            color: $blue;
                        }
                    }
                }
                &.search {
                    margin: 0 12px;
                    font-size: 17px;
                    
                    .iconWrapper {
                        min-width: 18px;
                        min-height: 18px;
                        display: inline-block;
                    }
                    a {
                        color: $blue;
                        
                        &:hover {
                            color: $gold;
                        }
                    }
                }
            }
        }
        
    }
}