.sidebar {
    border-left: 1px solid $gold;
    padding-left: 64px;
    padding-bottom: 64px;
    
    @include screen {
        margin-bottom: 64px;
    }
    @include touch {
        padding: 0;
        border: none;
        margin: 40px 0 50px 0;
    }
    
    h3 {
        margin: 30px 0;
    }
    p {
        margin-bottom: 25px;
        font-size: 18px;
    }
    .button {
        margin-bottom: 20px;
    }
}