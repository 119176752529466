#accommodations {
    .pageIntro {
        .columns {
            @include screen {
                flex-direction: row-reverse;
            }
        }
    }
    .suites {
        padding: 64px 0 0 0;
        background: $paper;
        
        h2 {
            text-align: center;
        }
        .divi {
            margin-bottom: 64px;
        }
    }
}
