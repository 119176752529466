#activities {
    .explore {
        width: 75vw;
        margin: 0 auto 64px auto;
        
        @include small-desktop {
            width: 100%;
        }
        @include touch {
            width: 100%;
            margin: 0 auto;
        }
        
        h2 {
            font-size: 28px;
        }
        .overview {
            h4 {
                font-family: $primary;
                font-size: 30px;
                font-weight: 700;
                margin: 20px 0;
            }
            p {
                margin-bottom: 25px;
                    
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        #tabs {
            background: $paper;
            padding: 25px;
            
            li {
                margin-bottom: 15px;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
                h3 {
                    font-size: 22px;
                    @extend .smooth;
                    
                    &:hover {
                        color: $red;
                    }
                }
                &.active {
                    h3 {
                        color: $red;
                    }
                }
            }
        }
        .tab-content {
            padding: 0 32px;
        }
        .columns {
            @include handheld {
                display: none;
            }
        }
        .expander {
            display: none;
            
            @include mobile {
                display: block;
            }
            
            .header {
                background: $paper;
                padding: 20px;
                border-top: 3px solid $white;
                @extend .smooth;

                .accordion-button {
                    cursor: pointer;

                    .fa-plus {
                        backface-visibility: hidden;
                        transform: rotate(0deg);
                        transform-style: preserve-3d;
                    }

                    &.expanded {
                        .fa-plus {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            .accordion-content {
                background: $paper;
                overflow: hidden;
                transition: height $transition;
                
                .overview {
                    padding: 5px 20px 20px 20px;
                }
             }
        }
    }
}